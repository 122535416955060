import Vue from 'vue'

export const state = {
  string: {},
  strings: []
}
export const getters = {
  string: ({ string }) => string,
  strings: ({ strings }) => strings
}
export const actions = {
  async getStrings({ commit }, payload) {
    const { mpptId } = payload
    try {
      const response = (await Vue.axios.get(`/string/list/mppt/${mpptId}`)).data
      commit('setStrings', response)
    } catch (error) {
      return error
    }
  },
  async getString({ commit, dispatch }, payload) {
    const { id } = payload
    try {
      const response = (await Vue.axios.get(`/string/list/${id}`)).data
      commit('setString', response)
      await dispatch(
        'panel/getPanels',
        {
          stringId: id
        },
        { root: true }
      )
      return true
    } catch (error) {
      return error
    }
  },
  async createString({ dispatch }, payload) {
    const { body } = payload
    try {
      const response = (await Vue.axios.post(`/string/create`, body)).data
      Vue.swal.fire({
        icon: 'success',
        title: 'Excelente',
        text: 'String creado exitosamente.',
        showConfirmButton: true
      })
      await dispatch('getStrings', {
        mpptId: body.mppt
      })
      return response
    } catch (error) {
      Vue.swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Ocurrió un error al crear el String.',
        showConfirmButton: true
      })
      return false
    }
  },
  async updateString({ dispatch }, payload) {
    const { id, body } = payload
    try {
      const response = (await Vue.axios.put(`/string/update/${id}`, body)).data
      Vue.swal.fire({
        icon: 'success',
        title: 'Excelente',
        text: 'String actualizado exitosamente.',
        showConfirmButton: true
      })
      await dispatch('getStrings', {
        mpptId: body.mppt
      })
      return response
    } catch (error) {
      Vue.swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Ocurrió un error al actualizar el String.',
        showConfirmButton: true
      })
      return false
    }
  },
  async deleteString({ dispatch }, payload) {
    const { id, mpptId } = payload
    try {
      await Vue.axios.delete(`/string/deleted/${id}`)
      Vue.swal.fire({
        icon: 'success',
        title: 'Excelente',
        text: 'String eliminado exitosamente.',
        showConfirmButton: true
      })
      dispatch('getStrings', {
        mpptId
      })
      return true
    } catch (error) {
      Vue.swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Ocurrió un error al eliminar el String.',
        showConfirmButton: true
      })
      return false
    }
  }
}
export const mutations = {
  setString(vuexState, payload) {
    vuexState.string = payload
  },
  setStrings(vuexState, payload) {
    vuexState.strings = payload
  }
}
