import Vue from 'vue'

export const state = {
  consumption: null,
  consumptionChart: []
}
export const getters = {
  consumption: ({ consumption }) => consumption,
  consumptionChart: ({ consumptionChart }) => consumptionChart
}
export const actions = {
  async getConsumptionModule({ commit }, { id }) {
    try {
      const response = (await Vue.axios.get(`v2/last/measurement/all/data/${id}`)).data
      commit('setConsumption', response)
    } catch (error) {
      console.log(error)
    }
  },
  async getConsumptionChart({ commit }, { interval, date_init, date_end, date, id }) {
    try {
      let response = null
      if (interval == 'range') {
        response = (
          await Vue.axios.get(
            `v2/measurement/range/${id}?date_start=${date_init}&date_end=${date_end}`
          )
        ).data
      } else {
        response = (
          await Vue.axios.get(
            interval === 'dayMonth'
              ? `v2/measurement/${id}?day=${date.format('DD')}&month=${date.format(
                  'MM'
                )}&year=${date.format('YYYY')}`
              : `v2/get/data/date/${id}?type=${interval}&date=${date.format('YYYY-MM-DD')}`
          )
        ).data
      }
      commit('setConsumptionChart', response)
      return response
    } catch (error) {
      console.log(error)
    }
  }
}
export const mutations = {
  setConsumption(vuexState, payload) {
    vuexState.consumption = payload
  },
  setConsumptionChart(vuexState, payload) {
    vuexState.consumptionChart = payload
  }
}
