import Vue from 'vue'
import VueRouter from 'vue-router'
import VueMeta from 'vue-meta'
import AuthGuard from '@/utils/auth.guard'

import routes from './routes'

Vue.use(VueRouter)
Vue.use(VueMeta, {
  keyName: 'page'
})

const router = new VueRouter({
  routes,
  mode: 'history',
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})
router.beforeEach(AuthGuard)
export default router
