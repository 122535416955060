import Vue from 'vue'

export const state = {
  communes: [],
  configurations: []
}
export const getters = {
  communes: ({ communes }) => communes,
  configurations: ({ configurations }) => configurations
}
export const actions = {
  async getCommunes({ commit }) {
    try {
      const response = (await Vue.axios.get(`/commune-all/list`)).data
      commit('setCommunes', response)
    } catch (error) {
      Vue.$notify('error', 'Error', 'Ocurrió un error al obtener el listado de Comunas.', {
        duration: 3000,
        permanent: false
      })
    }
  },
  async getConfig({ commit }) {
    try {
      const response = (await Vue.axios.get(`/list/settings`)).data
      commit('setConfig', response)
    } catch (error) {
      Vue.$notify(
        'error',
        'Error',
        'Ocurrió un error al obtener el listado de configuraciones.',
        {
          duration: 3000,
          permanent: false
        }
      )
    }
  },
  async updateConfiguration(_, payload) {
    const { id, name, environnement_factor, time_zone } = payload
    try {
      const response = (
        await Vue.axios.put(`/update/settings/parameters/${id}`, {
          name,
          environnement_factor,
          time_zone
        })
      ).data
      Vue.swal.fire({
        icon: 'success',
        title: 'Excelente',
        text: 'Configuración actualizada exitosamente.',
        showConfirmButton: true
      })
      return response
    } catch (error) {
      Vue.swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Ocurrió un error al actualizar la configuración.',
        showConfirmButton: true
      })
      return false
    }
  }
}
export const mutations = {
  setConfig(vuexState, payload) {
    vuexState.configurations = payload
  },
  setCommunes(vuexState, payload) {
    vuexState.communes = payload
  }
}
