import Vue from 'vue'

export const state = {
  trademark: {},
  trademarks: [],
  equipment: {},
  equipments: [],
  investorsMicro: []
}
export const getters = {
  trademark: ({ trademark }) => trademark,
  trademarks: ({ trademarks }) => trademarks,
  equipment: ({ equipment }) => equipment,
  equipments: ({ equipments }) => equipments,
  investorsMicro: ({ investorsMicro }) => investorsMicro
}
export const actions = {
  //Obtener los inversores de Fronius / Goodwe
  async getInvestorsMicro({ commit }) {
    try {
      const response = (await Vue.axios.get(`/investor-all/microservice`)).data
      commit('setInvestorsMicro', response.fronius)
    } catch (error) {
      console.log(error)
    }
  },
  async getTrademarks({ commit }) {
    try {
      const response = (await Vue.axios.get(`/trademark-all/`)).data
      commit('setTrademarks', response)
    } catch (error) {
      return error
    }
  },
  async getTrademark({ commit }, payload) {
    const { id } = payload
    try {
      const response = (await Vue.axios.get(`/trademark/${id}`)).data
      commit('setTrademark', response)
      return true
    } catch (error) {
      return error
    }
  },
  async createTrademark({ dispatch }, payload) {
    const { body } = payload
    const { name, image } = body
    const formData = new FormData()
    if (name) formData.append('name', name)
    if (image) formData.append('image', image)

    try {
      const response = (await Vue.axios.post(`/trademark/create`, formData)).data
      Vue.swal.fire({
        icon: 'success',
        title: 'Excelente',
        text: 'Marca creada exitosamente.',
        showConfirmButton: true
      })
      dispatch('getTrademarks')
      return response
    } catch (error) {
      Vue.swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Ocurrió un error al crear la marca.',
        showConfirmButton: true
      })
      return false
    }
  },
  async updateTrademark({ dispatch }, payload) {
    const { id, body } = payload
    const { name, image } = body
    const formData = new FormData()
    if (name) formData.append('name', name)
    if (image) formData.append('image', image)
    try {
      const response = (await Vue.axios.put(`/trademark/update/${id}`, formData)).data
      Vue.swal.fire({
        icon: 'success',
        title: 'Excelente',
        text: 'Marca actualizada exitosamente.',
        showConfirmButton: true
      })
      dispatch('getTrademarks')
      return response
    } catch (error) {
      Vue.swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Ocurrió un error al actualizar la marca.',
        showConfirmButton: true
      })
      return false
    }
  },
  async deleteTrademark({ dispatch }, payload) {
    const { id } = payload
    try {
      await Vue.axios.delete(`/trademark/deleted/${id}`)
      Vue.swal.fire({
        icon: 'success',
        title: 'Excelente',
        text: 'Marca eliminada exitosamente.',
        showConfirmButton: true
      })
      dispatch('getTrademarks')
      return true
    } catch (error) {
      Vue.swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Ocurrió un error al eliminar la marca.',
        showConfirmButton: true
      })
      return false
    }
  },
  async getEquipments({ commit }) {
    try {
      const response = (await Vue.axios.get(`/equipment-all/`)).data
      commit('setEquipments', response)
    } catch (error) {
      return error
    }
  },
  async getEquipment({ commit }, payload) {
    const { id } = payload
    try {
      const response = (await Vue.axios.get(`/equipment/${id}`)).data
      commit('setEquipment', response)
      return true
    } catch (error) {
      return false
    }
  },
  async createEquipment({ dispatch }, payload) {
    const { body } = payload
    const { name, image, trademark, model, station_code, description } = body
    const formData = new FormData()
    if (name) formData.append('name', name)
    if (image) formData.append('image', image)
    if (trademark) formData.append('trademark', trademark)
    if (model) formData.append('model', model)
    if (station_code) formData.append('station_code', station_code)
    if (description) formData.append('description', description)

    try {
      const response = (await Vue.axios.post(`/equipment/create`, formData)).data
      Vue.swal.fire({
        icon: 'success',
        title: 'Excelente',
        text: 'Equipo creado exitosamente.',
        showConfirmButton: true
      })
      dispatch('getEquipments')
      return response
    } catch (error) {
      Vue.swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Ocurrió un error al crear el equipo.',
        showConfirmButton: true
      })
      return false
    }
  },
  async updateEquipment({ dispatch }, payload) {
    const { id, body } = payload
    const { name, image, trademark, model, station_code, description } = body
    const formData = new FormData()
    if (name) formData.append('name', name)
    if (image) formData.append('image', image)
    if (trademark) formData.append('trademark', trademark)
    if (model) formData.append('model', model)
    if (station_code) formData.append('station_code', station_code)
    if (description) formData.append('description', description)
    try {
      const response = (await Vue.axios.put(`/equipment/update/${id}`, formData)).data
      Vue.swal.fire({
        icon: 'success',
        title: 'Excelente',
        text: 'Equipo actualizado exitosamente.',
        showConfirmButton: true
      })
      dispatch('getEquipments')
      return response
    } catch (error) {
      Vue.swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Ocurrió un error al actualizar el equipo.',
        showConfirmButton: true
      })
      return false
    }
  },
  async deleteEquipment({ dispatch }, payload) {
    const { id } = payload
    try {
      await Vue.axios.delete(`/equipment/deleted/${id}`)
      Vue.swal.fire({
        icon: 'success',
        title: 'Excelente',
        text: 'Equipo eliminado exitosamente.',
        showConfirmButton: true
      })
      dispatch('getEquipments')
      return true
    } catch (error) {
      Vue.swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Ocurrió un error al eliminar el equipo.',
        showConfirmButton: true
      })
      return false
    }
  },
  async createUser({ commit }, payload) {
    commit('clearError')
    commit('setProcessing', true)
    try {
      const response = (await Vue.axios.post('/auth/create/user', payload)).data
      Vue.swal.fire({
        icon: 'success',
        title: 'Excelente',
        text: 'Usuario creado exitosamente.',
        showConfirmButton: true
      })
      return response
    } catch (error) {
      commit('setError', error.message)
      let message = 'Ocurrió un error al crear el usuario.'
      if (error.response?.data?.non_field_errors?.length) {
        message = 'Este correo electrónico ya se encuentra registrado.'
      }
      setTimeout(() => {
        commit('clearError')
      }, 3000)
      Vue.swal.fire({
        icon: 'error',
        title: 'Error',
        text: message,
        showConfirmButton: true
      })
      return false
    } finally {
      commit('setProcessing', false)
    }
  }
}
export const mutations = {
  setInvestorsMicro(vuexState, payload) {
    vuexState.investorsMicro = payload
  },
  setTrademarks(vuexState, payload) {
    vuexState.trademarks = payload
  },
  setTrademark(vuexState, payload) {
    vuexState.trademark = payload
  },
  setEquipments(vuexState, payload) {
    vuexState.equipments = payload
  },
  setEquipment(vuexState, payload) {
    vuexState.equipment = payload
  }
}
