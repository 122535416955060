import Vue from 'vue'

export const state = {
  investorRegistersMonitoring: []
}
export const getters = {
  investorRegistersMonitoring: ({ investorRegistersMonitoring }) => investorRegistersMonitoring
}
export const actions = {
  async getInvestorRegisterMonitoring({ commit }, payload) {
    const { investorId, module } = payload
    try {
      const response = (await Vue.axios.get(`${module}/measurements/${investorId}`)).data
      const wrapedData =
        module == 'power-plant'
          ? response
          : response.reduce(function (a, b) {
              return a.concat(b)
            })
      commit('setInvestorRegistersMonitoring', wrapedData[0])
      return wrapedData
    } catch (error) {
      console.log(error)
    }
  }
}
export const mutations = {
  setInvestorRegistersMonitoring(vuexState, payload) {
    vuexState.investorRegistersMonitoring = payload
  }
}
