export const countryChile = 'CL'
export const countryColombia = 'CO'
export const countryApp = process.env.VUE_APP_COUNTRY
export const logoApp =
  process.env.VUE_APP_COUNTRY === countryChile
    ? require('@/assets/images/app/logos/logo_fluxsolar.png')
    : require('@/assets/images/app/logos/logo_fluxsolar_co.png')
export const logoAppWhite =
  process.env.VUE_APP_COUNTRY === countryChile
    ? require('@/assets/images/app/logos/logo_fluxsolar_blanco.png')
    : require('@/assets/images/app/logos/logo_fluxsolar_co.png')

export const nameApp =
  process.env.VUE_APP_COUNTRY === countryChile ? 'Flux Solar' : 'Flux Terpel'

export const defaultMenuType = 'menu-default' // 'menu-default', 'menu-sub-hidden', 'menu-hidden';
export const adminRoot = '/app'
export const monitoringUrl = '/app/monitoring'
export const searchPath = `${adminRoot}/#`
export const apiUrl = `${process.env.VUE_APP_BASE_URL}`

export const subHiddenBreakpoint = 1440
export const menuHiddenBreakpoint = 768

export const defaultLocale = 'es'
export const defaultDirection = 'ltr'
export const localeOptions = [
  { id: 'en', name: 'English LTR', direction: 'ltr' },
  { id: 'es', name: 'Español', direction: 'ltr' },
  { id: 'enrtl', name: 'English RTL', direction: 'rtl' }
]

export const isAuthGuardActive = true
export const themeRadiusStorageKey = 'theme_radius'
export const themeSelectedColorStorageKey = 'theme_selected_color'
export const defaultColor = 'light.blueolympic'

export const coords_santiago = { lat: -33.447487, lng: -70.673676 }
