import Vue from 'vue'
import BootstrapVue from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import Vuelidate from 'vuelidate'
import VueMask from 'v-mask'
import VueRouter from 'vue-router'
import vco from 'v-click-outside'
import router from './router/index'
import Scrollspy from 'vue2-scrollspy'
import tinymce from 'vue-tinymce-editor'
// Colxx Component Add
import Colxx from './components/Common/Colxx'

// Sweet Alert
import 'sweetalert2/dist/sweetalert2.min.css'
import VueSweetalert2 from 'vue-sweetalert2'
const optionsVueSweetAlert = {
  confirmButtonColor: '#FFA500',
  cancelButtonColor: '#f56666'
}

//Styles
import '../src/design/app.scss'

import store from '@/state/index'
import App from './App.vue'
import i18n from './i18n'
import { initFirebaseBackend } from './authUtils'
import { configureFakeBackend } from './helpers/fake-backend'

const firebaseConfig = {
  apiKey: process.env.VUE_APP_APIKEY,
  authDomain: process.env.VUE_APP_AUTHDOMAIN,
  databaseURL: process.env.VUE_APP_VUE_APP_DATABASEURL,
  projectId: process.env.VUE_APP_PROJECTId,
  storageBucket: process.env.VUE_APP_STORAGEBUCKET,
  messagingSenderId: process.env.VUE_APP_MESSAGINGSENDERID,
  appId: process.env.VUE_APP_APPId,
  measurementId: process.env.VUE_APP_MEASUREMENTID
}

if (process.env.VUE_APP_DEFAULT_AUTH === 'firebase') {
  initFirebaseBackend(firebaseConfig)
} else {
  configureFakeBackend()
}

//Directives
import './directives/permission.js'

Vue.component('tinymce', tinymce)
Vue.use(VueRouter)
Vue.use(vco)
Vue.use(Scrollspy)
const VueScrollTo = require('vue-scrollto')
Vue.use(VueScrollTo)
Vue.config.productionTip = false

Vue.use(BootstrapVue)
Vue.use(Vuelidate)
Vue.use(VueMask)
Vue.use(require('vue-chartist'))
Vue.use(VueSweetalert2, optionsVueSweetAlert)
Vue.component('apexchart', VueApexCharts)
Vue.component('b-colxx', Colxx)

const moment = require('moment')
require('moment/locale/es')
moment.updateLocale('es', {
  calendar: {
    nextDay: function () {
      return '[Mañana]'
    },
    sameDay: function () {
      return '[Hoy]'
    }
  }
})
Vue.use(require('vue-moment'), {
  moment
})

import './plugins/axios'
import './plugins/google-maps'

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App)
}).$mount('#app')
