//import para crear los componentes de google maps
import Vue from 'vue'
import * as VueGoogleMaps from 'vue2-google-maps'

//google maps
Vue.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_MAPS_KEY,
    libraries: 'places' // necessary for places input,
  }
})
