import { isAuthGuardActive } from '../constants/config'
import { setCurrentUser } from '.'
import store from '../state'

export default async (to, from, next) => {
  if (to.matched.some((record) => record.meta.loginRequired)) {
    if (isAuthGuardActive) {
      const user = store.getters['user/userAuth'] || (await store.dispatch('user/getProfile'))
      if (user) {
        if (to.path === '/login') {
          next('/')
        } else {
          next()
        }
        // const roleArrayHierarchic = to.matched
        //   .filter(x => x.meta.roles)
        //   .map(x => x.meta.roles);
        // if (roleArrayHierarchic.every(x => x.includes(user.role))) {
        //   next();
        // } else {
        //   next("/unauthorized");
        // }
      } else {
        setCurrentUser(null)
        next('/login')
      }
    } else {
      next()
    }
  } else {
    next()
  }
}
