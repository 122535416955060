/* eslint-disable no-unused-vars */
import Vue from 'vue'
import store from '@/state'

const GETTER_USER_IS_ADMIN = 'user/userIsAdmin'
const GETTER_USER_IS_SUPERADMIN = 'user/userIsSuperadmin'

const verifyPermission = (el, binding, getter) => {
  const permission = store.getters[getter]()
  if (permission || !binding.value) {
    el.style.display = 'visible'
  } else {
    el.style.display = 'none'
  }
}
Vue.directive('admin', {
  inserted(el, binding) {
    store.watch(
      (state, getters) => getters[GETTER_USER_IS_ADMIN],
      (newValue) => {
        verifyPermission(el, binding, GETTER_USER_IS_ADMIN)
      }
    )
    verifyPermission(el, binding, GETTER_USER_IS_ADMIN)
  }
})

Vue.directive('superadmin', {
  inserted(el, binding) {
    store.watch(
      (state, getters) => getters[GETTER_USER_IS_SUPERADMIN],
      (newValue) => {
        verifyPermission(el, binding, GETTER_USER_IS_SUPERADMIN)
      }
    )
    verifyPermission(el, binding, GETTER_USER_IS_SUPERADMIN)
  }
})
