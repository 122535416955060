import { STATUS_TICKET_CLOSED, STATUS_TICKET_ACTIVE } from '@/constants/ticket'

export default {
  /**
   * Get Full Name User from Ticket
   * @param {*} ticket
   * @returns {String}
   */
  getFullNameUserToTicket(ticket) {
    return ticket?.customuser?.first_name
      ? `${ticket?.customuser?.first_name} ${ticket?.customuser?.last_name}`
      : 'Usuario Administrador'
  },

  /**
   * Get Full Name User
   * @param {*} user
   * @returns {String}
   */
  getFullNameUser(user) {
    return user?.first_name
      ? `${user?.first_name} ${user?.last_name}`
      : 'Usuario Administrador'
  },

  /**
   * Get Text Status Ticket
   * @param {*} ticket
   * @returns {String}
   */
  getStatusNameFromTicket(ticket) {
    switch (ticket.status) {
      case STATUS_TICKET_ACTIVE:
        return 'Activo'
      case STATUS_TICKET_CLOSED:
        return 'Cerrado'
      default:
        return 'Cerrado'
    }
  }
}
