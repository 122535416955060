import Vue from 'vue'

export const state = {
  notificationsUserAuth: [],
  notifications: [],
  typesNotifications: [],
  urlsSendNotification: {
    1: '/notification-tag-user/create',
    2: '/notification-project-user/create',
    3: '/notification-users/create'
  }
}
export const getters = {
  notificationsUserAuth: ({ notificationsUserAuth }) => notificationsUserAuth,
  notifications: ({ notifications }) => notifications,
  typesNotifications: ({ typesNotifications }) => typesNotifications
}
export const actions = {
  async getNotificationsUserAuth({ commit }) {
    try {
      const response = (await Vue.axios.get(`/user-notification/list`)).data
      commit(
        'setNotificationsUserAuth',
        response.sort((a, b) => {
          return new Date(b.created_at) < new Date(a.created_at) ? -1 : 1
        })
      )
    } catch (error) {
      return error
    }
  },
  async getNotifications({ commit }) {
    try {
      const response = (await Vue.axios.get(`/notification/list`)).data
      commit('setNotifications', response)
    } catch (error) {
      return error
    }
  },
  async getTypeNotifications({ commit }) {
    try {
      const response = (await Vue.axios.get(`/type-notification/list`)).data
      commit('setTypeNotifications', response)
    } catch (error) {
      return error
    }
  },
  async sendNewNotification({ dispatch, state }, payload) {
    try {
      payload = {
        ...payload
      }
      const response = (
        await Vue.axios.post(state.urlsSendNotification[payload.type_notification], payload)
      ).data
      await dispatch('getNotifications')
      Vue.swal.fire({
        icon: 'success',
        title: 'Excelente',
        text: 'Notificación enviada exitosamente.',
        showConfirmButton: true
      })
      return response
    } catch (error) {
      Vue.swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Ocurrió un error al enviar la notificación.',
        showConfirmButton: true
      })
      return error
    }
  }
}
export const mutations = {
  setNotification(vuexState, payload) {
    vuexState.notifications.unshift(payload)
  },
  setNotificationsUserAuth(vuexState, payload) {
    vuexState.notificationsUserAuth = payload
  },
  setNotifications(vuexState, payload) {
    vuexState.notifications = payload
  },
  setTypeNotifications(vuexState, payload) {
    vuexState.typesNotifications = payload
  }
}
