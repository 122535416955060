// import store from '@/state/index'

export default [
  {
    path: '/',
    redirect: `app/monitoring`
  },
  {
    path: '/app/monitoring',
    name: 'monitoring',
    meta: { loginRequired: true },
    component: () => import('./views/monitoring/monitoring')
  },
  // # Projects
  {
    path: '/app/projects',
    component: () => import(/* webpackChunkName: "piaf" */ './views/projects/projects')
  },
  {
    path: '/app/tags',
    component: () => import(/* webpackChunkName: "piaf" */ './views/admin/tags/tags')
  },
  {
    path: '/app/equipments',
    component: () => import(/* webpackChunkName: "piaf" */ './views/admin/equipments')
  },
  {
    path: '/app/trademarks',
    component: () => import(/* webpackChunkName: "piaf" */ './views/admin/trademarks')
  },
  {
    path: '/app/users',
    component: () => import(/* webpackChunkName: "piaf" */ './views/admin/users/users')
  },
  {
    path: '/app/notifications',
    component: () =>
      import(/* webpackChunkName: "piaf" */ './views/admin/notifications/Notifications')
  },
  {
    path: '/app/configuration',
    component: () => import(/* webpackChunkName: "piaf" */ './views/admin/configuration/index')
  },

  // #Tickets
  {
    path: '/app/tickets/list',
    name: 'listTickets',
    component: () => import(/* webpackChunkName: "piaf" */ './views/admin/tickets/ListTickets')
  },
  {
    path: '/app/tickets/categories',
    component: () =>
      import(/* webpackChunkName: "piaf" */ './views/admin/tickets/CategoriesTickets')
  },
  {
    path: '/app/tickets/:id/show',
    name: 'showTicket',
    component: () => import(/* webpackChunkName: "piaf" */ './views/admin/tickets/ShowTicket')
  },
  // #Profile
  {
    path: '/app/profile/me',
    component: () => import(/* webpackChunkName: "login" */ './views/account/profile')
  },
  // #Auth
  {
    path: '/login',
    name: 'login',
    component: () => import('./views/account/login'),
    meta: {}
  },
  {
    path: '/user/auth/login/google',
    component: () =>
      import(/* webpackChunkName: "login" */ './views/account/VerifyLoginGoogle')
  },
  {
    path: '/user/account-activate',
    component: () => import(/* webpackChunkName: "login" */ './views/account/VerifyEmail')
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import('./views/account/register'),
    meta: {}
  },
  {
    path: '/forgot-password',
    name: 'Forgot password',
    component: () => import('./views/account/forgot-password'),
    meta: {}
  },
  {
    path: '/user/reset-password/:code/:token',
    name: 'reset-password',
    component: () => import(/* webpackChunkName: "user" */ './views/account/reset-password')
  },
  {
    path: '/logout',
    name: 'logout',
    meta: {
      loginRequired: true
    }
  },
  {
    path: '/404',
    name: '404',
    component: require('./views/utility/404').default
  },
  {
    path: '*',
    redirect: '404'
  }
]
