import Vue from 'vue'

export const state = {
  panel: {},
  panels: []
}
export const getters = {
  panel: ({ panel }) => panel,
  panels: ({ panels }) => panels
}
export const actions = {
  async getPanels({ commit }, payload) {
    const { stringId } = payload
    try {
      const response = (await Vue.axios.get(`/panel/list/string/${stringId}`)).data
      commit('setPanels', response)
    } catch (error) {
      return error
    }
  },
  async getPanel({ commit }, payload) {
    const { id } = payload
    try {
      const response = (await Vue.axios.get(`/panel/list/${id}`)).data
      commit('setPanel', response)
      return true
    } catch (error) {
      return false
    }
  },
  async createPanel({ dispatch }, payload) {
    const { body } = payload
    try {
      const response = (await Vue.axios.post(`/panel/create`, body)).data
      Vue.swal.fire({
        icon: 'success',
        title: 'Excelente',
        text: 'Panel creado exitosamente.',
        showConfirmButton: true
      })
      await dispatch('getPanels', {
        stringId: body.string
      })
      return response
    } catch (error) {
      Vue.swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Ocurrió un error al crear el Panel.',
        showConfirmButton: true
      })
      return false
    }
  },
  async updatePanel({ dispatch }, payload) {
    const { id, body } = payload
    try {
      const response = (await Vue.axios.put(`/panel/update/${id}`, body)).data
      Vue.swal.fire({
        icon: 'success',
        title: 'Excelente',
        text: 'Panel actualizado exitosamente.',
        showConfirmButton: true
      })
      await dispatch('getPanels', {
        stringId: body.string
      })
      return response
    } catch (error) {
      Vue.swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Ocurrió un error al actualizar el Panel.',
        showConfirmButton: true
      })
      return false
    }
  },
  async deletePanel({ dispatch }, payload) {
    const { id, stringId } = payload
    try {
      await Vue.axios.delete(`/panel/deleted/${id}`)
      Vue.swal.fire({
        icon: 'success',
        title: 'Excelente',
        text: 'Panel eliminado exitosamente.',
        showConfirmButton: true
      })
      dispatch('getPanels', {
        stringId
      })
      return true
    } catch (error) {
      Vue.swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Ocurrió un error al eliminar el Panel.',
        showConfirmButton: true
      })
      return false
    }
  }
}
export const mutations = {
  setPanel(vuexState, payload) {
    vuexState.panel = payload
  },
  setPanels(vuexState, payload) {
    vuexState.panels = payload
  }
}
