import Vue from 'vue'
import ticketHelper from '@/helpers/ticket'
import moment from 'moment'

export const state = {
  ticket: null,
  categoriesTickets: [],
  messagesTicket: [],
  tickets: []
}
export const getters = {
  tickets: ({ tickets }) => tickets,
  ticket: ({ ticket }) => ticket,
  messagesTicket: ({ messagesTicket }) => messagesTicket,
  categoriesTickets: ({ categoriesTickets }) => categoriesTickets
}
export const actions = {
  async getTickets({ commit }) {
    try {
      const response = (await Vue.axios.get(`/ticket/list`)).data
      commit('setTickets', response)
    } catch (error) {
      return error
    }
  },
  async getCategoriesTickets({ commit }) {
    try {
      const response = (await Vue.axios.get(`/ticket/type/list`)).data
      commit('setCategoriesTickets', response)
    } catch (error) {
      return error
    }
  },
  async getMessagesTicket({ commit }, payload) {
    const { id } = payload
    try {
      const response = (await Vue.axios.get(`/ticket/message/of/ticket/${id}`)).data
      commit('setMessagesTicket', response)
    } catch (error) {
      return error
    }
  },
  async getTicket({ commit }, payload) {
    const { id } = payload
    try {
      const response = (await Vue.axios.get(`/ticket/detail/${id}`)).data
      commit('setTicket', response)
      return true
    } catch (error) {
      return error
    }
  },
  async changeStatusTicket({ dispatch }, payload) {
    const { id, status } = payload
    try {
      const response = (await Vue.axios.put(`/ticket/change/status/${id}`, { status })).data
      Vue.swal.fire({
        icon: 'success',
        title: 'Excelente',
        text: 'Estatus del ticket modificado exitosamente.',
        showConfirmButton: true
      })
      dispatch('getTicket', { id })
      return response
    } catch (error) {
      return error
    }
  },
  async sendMessageTicket({ dispatch }, payload) {
    const { customuser, content, media, ticket } = payload.body
    const formData = new FormData()
    formData.append('content', content)
    formData.append('ticket', ticket)
    formData.append('customuser', customuser)
    if (media) {
      formData.append('media', media)
    }
    try {
      const response = (
        await Vue.axios.post(`/ticket/message/create`, formData, {
          headers: { 'Content-Type': 'multipart/form-data' }
        })
      ).data
      Vue.swal.fire({
        icon: 'success',
        title: 'Excelente',
        text: 'Mensaje enviado exitosamente.',
        showConfirmButton: true
      })
      dispatch('getMessagesTicket', { id: ticket })
      return response
    } catch (error) {
      return error
    }
  },
  async deleteMessageTicket({ commit }, payload) {
    const { id } = payload
    try {
      await Vue.axios.delete(`/ticket/message/delete/${id}`)
      Vue.swal.fire({
        icon: 'success',
        title: 'Excelente',
        text: 'Mensaje de Ticket eliminado exitosamente.',
        showConfirmButton: true
      })
      commit('spliceMessageDeletedForId', id)
      return true
    } catch (error) {
      Vue.swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Ocurrió un error al eliminar el mensaje del ticket.',
        showConfirmButton: true
      })
      return false
    }
  },
  async createTicket({ dispatch }, payload) {
    const { title, content, customuser, status, type, media, user_to_assign_id } = payload.body
    const formData = new FormData()
    formData.append('title', title)
    formData.append('content', content)
    formData.append('customuser', customuser)
    formData.append('type', type)
    formData.append('status', status)
    if (media) {
      formData.append('media', media)
    }
    try {
      const response = (await Vue.axios.post(`/ticket/create`, formData)).data
      await dispatch('updateUserAssignedTicket', {
        id: response.id,
        title,
        status,
        user_admin: user_to_assign_id
      })
      Vue.swal.fire({
        icon: 'success',
        title: 'Excelente',
        text: 'Ticket creado exitosamente.',
        showConfirmButton: true
      })
      await dispatch('getTickets')
      return response
    } catch (error) {
      Vue.swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Ocurrió un error al crear el ticket.',
        showConfirmButton: true
      })
      return false
    }
  },
  async updateUserAssignedTicket(context, payload) {
    const { id, title, status, user_admin } = payload
    try {
      const response = (
        await Vue.axios.put(`/ticket/update/${id}`, {
          title,
          status,
          user_admin
        })
      ).data
      return response
    } catch (error) {
      return { error, context }
    }
  },
  async deleteUserAssignedTicket(context, payload) {
    const { id, title, status, user_admin } = payload
    try {
      const response = (
        await Vue.axios.put(`/ticket/remove/admin/${id}`, {
          title,
          status,
          user_admin
        })
      ).data
      return response
    } catch (error) {
      return { error, context }
    }
  },
  async updateTicket({ dispatch }, payload) {
    const { id, body } = payload
    try {
      const response = (await Vue.axios.put(`/ticket/update/${id}`, body)).data
      const payloadToUpdateUserAssigned = {
        id,
        title: body.title,
        status: body.status
      }
      if (body.prevUserAdminAssigned) {
        await dispatch('deleteUserAssignedTicket', {
          ...payloadToUpdateUserAssigned,
          user_admin: body.prevUserAdminAssigned
        })
      }
      await dispatch('updateUserAssignedTicket', {
        ...payloadToUpdateUserAssigned,
        user_admin: body.user_to_assign_id
      })
      Vue.swal.fire({
        icon: 'success',
        title: 'Excelente',
        text: 'Ticket actualizado exitosamente.',
        showConfirmButton: true
      })
      await dispatch('getTickets')
      return response
    } catch (error) {
      Vue.swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Ocurrió un error al actualizar el ticket.',
        showConfirmButton: true
      })
      return false
    }
  },
  async deleteTicket({ dispatch }, payload) {
    const { id } = payload
    try {
      await Vue.axios.delete(`/ticket/delete/${id}`)
      Vue.swal.fire({
        icon: 'success',
        title: 'Excelente',
        text: 'Ticket eliminado exitosamente.',
        showConfirmButton: true
      })
      dispatch('getTickets')
      return true
    } catch (error) {
      Vue.swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Ocurrió un error al eliminar el ticket.',
        showConfirmButton: true
      })
      return false
    }
  },
  async createCategoryTicket({ dispatch }, payload) {
    const { body } = payload
    try {
      const response = (await Vue.axios.post(`/ticket/type/create`, body)).data
      Vue.swal.fire({
        icon: 'success',
        title: 'Excelente',
        text: 'Categoria de Ticket creada exitosamente.',
        showConfirmButton: true
      })
      await dispatch('getCategoriesTickets')
      return response
    } catch (error) {
      Vue.swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Ocurrió un error al crear la categoria de ticket.',
        showConfirmButton: true
      })
      return false
    }
  },
  async updateCategoryTicket({ dispatch }, payload) {
    const { id, body } = payload
    try {
      const response = (await Vue.axios.put(`/ticket/type/update/${id}`, body)).data
      Vue.swal.fire({
        icon: 'success',
        title: 'Excelente',
        text: 'Categoria de Ticket actualizada exitosamente.',
        showConfirmButton: true
      })
      await dispatch('getCategoriesTickets')
      return response
    } catch (error) {
      Vue.swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Ocurrió un error al actualizar la categoria de ticket.',
        showConfirmButton: true
      })
      return false
    }
  },
  async deleteCategoryTicket({ dispatch }, payload) {
    const { id } = payload
    try {
      await Vue.axios.delete(`/ticket/type/delete/${id}`)
      Vue.swal.fire({
        icon: 'success',
        title: 'Excelente',
        text: 'Categoria de Ticket eliminada exitosamente.',
        showConfirmButton: true
      })
      dispatch('getCategoriesTickets')
      return true
    } catch (error) {
      Vue.swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Ocurrió un error al eliminar la categoria del ticket.',
        showConfirmButton: true
      })
      return false
    }
  }
}
export const mutations = {
  setTicket(vuexState, payload) {
    const user_fullname = ticketHelper.getFullNameUserToTicket(payload)
    const status_name = ticketHelper.getStatusNameFromTicket(payload)
    vuexState.ticket = { ...payload, user_fullname, status_name }
  },
  setTickets(vuexState, payload) {
    vuexState.tickets = payload.map((ticket) => {
      const [user_to_assign] = ticket.assigned_user_admin
      return {
        ...ticket,
        user_fullname: ticketHelper.getFullNameUserToTicket(ticket),
        status_name: ticketHelper.getStatusNameFromTicket(ticket),
        user_to_assign: {
          ...user_to_assign,
          fullname: user_to_assign
            ? ticketHelper.getFullNameUser(user_to_assign)
            : 'Sin asignar'
        }
      }
    })
  },
  setMessagesTicket(vuexState, payload) {
    vuexState.messagesTicket = payload.map((message) => {
      return {
        ...message,
        user_fullname: ticketHelper.getFullNameUserToTicket(message),
        time_ago: moment(message.created_at).fromNow()
      }
    })
  },
  setCategoriesTickets(vuexState, payload) {
    vuexState.categoriesTickets = payload
  },
  spliceMessageDeletedForId(vuexState, messageId) {
    vuexState.messagesTicket = vuexState.messagesTicket.filter(
      (message) => message.id !== messageId
    )
  }
}
