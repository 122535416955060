import Vue from 'vue'
export const state = {
  tag: {},
  tags: [],
  tagsUserAuth: [],
  tagUser: {
    customuser: {}
  },
  tagUsers: [],
  tagProject: {},
  tagProjects: []
}
export const getters = {
  tag: ({ tag }) => tag,
  tags: ({ tags }) => tags,
  tagsUserAuth: ({ tagsUserAuth }) => tagsUserAuth,
  tagUser: ({ tagUser }) => tagUser,
  tagUsers: ({ tagUsers }) => tagUsers,
  tagProject: ({ tagProject }) => tagProject,
  tagProjects: ({ tagProjects }) =>
    tagProjects.sort(function (a, b) {
      if (a.name > b.name) {
        return 1
      }
      if (a.name < b.name) {
        return -1
      }
      // a must be equal to b
      return 0
    })
}
export const actions = {
  async getTags({ commit }) {
    try {
      const response = (await Vue.axios.get(`/tag-all/list`)).data
      commit('setTags', response)
    } catch (error) {
      return error
    }
  },
  async getTagsUserAuth({ commit }) {
    try {
      const response = (await Vue.axios.get(`/tag-user-all/list`)).data
      commit(
        'setTagsUserAuth',
        response.map((item) => item.tag)
      )
    } catch (error) {
      return error
    }
  },
  async getTag({ commit, dispatch }, payload) {
    const { id } = payload
    try {
      const response = (await Vue.axios.get(`/tag/list/${id}`)).data
      commit('setTag', response)
      await dispatch('getTagUsers', {
        tagId: id
      })
      await dispatch('getTagProjects', {
        tagId: id
      })
      return true
    } catch (error) {
      return error
    }
  },
  async createTag({ dispatch }, payload) {
    const { body } = payload
    try {
      const response = (await Vue.axios.post(`/tag/create`, body)).data
      Vue.swal.fire({
        icon: 'success',
        title: 'Excelente',
        text: 'Etiqueta creada exitosamente.',
        showConfirmButton: true
      })
      await dispatch('getTags')
      return response
    } catch (error) {
      Vue.swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Ocurrió un error al crear la Etiqueta.',
        showConfirmButton: true
      })
      return false
    }
  },
  async updateTag({ dispatch }, payload) {
    const { id, body } = payload
    try {
      const response = (await Vue.axios.put(`/tag/update/${id}`, body)).data
      Vue.swal.fire({
        icon: 'success',
        title: 'Excelente',
        text: 'Etiqueta actualizada exitosamente.',
        showConfirmButton: true
      })
      await dispatch('getTags')
      return response
    } catch (error) {
      Vue.swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Ocurrió un error al actualizar la Etiqueta.',
        showConfirmButton: true
      })
      return false
    }
  },
  async deleteTag({ dispatch }, payload) {
    const { id } = payload
    try {
      await Vue.axios.delete(`/tag/deleted/${id}`)
      Vue.swal.fire({
        icon: 'success',
        title: 'Excelente',
        text: 'Etiqueta eliminada exitosamente.',
        showConfirmButton: true
      })
      dispatch('getTags')
      return true
    } catch (error) {
      Vue.swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Ocurrió un error al eliminar la Etiqueta.',
        showConfirmButton: true
      })
      return false
    }
  },
  async getTagUsers({ commit }, payload) {
    const { tagId } = payload
    try {
      const response = (await Vue.axios.get(`/tag-user-tag/list/${tagId}`)).data
      commit('setTagUsers', response)
    } catch (error) {
      return error
    }
  },
  async getTagUser({ commit }, payload) {
    const { id } = payload
    try {
      const response = (await Vue.axios.get(`/tag-user/list/${id}`)).data
      commit('setTagUser', response)
      return true
    } catch (error) {
      return error
    }
  },
  async createTagUser({ dispatch }, payload) {
    const { body } = payload
    try {
      const response = (await Vue.axios.post(`/tag-user/create`, body)).data
      dispatch('getTagUsers', {
        tagId: body.tag
      })
      Vue.swal.fire({
        icon: 'success',
        title: 'Excelente',
        text: 'Usuario agregado exitosamente.',
        showConfirmButton: true
      })
      return response
    } catch (error) {
      Vue.swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Ocurrió un error al agregar el usuario',
        showConfirmButton: true
      })
      return false
    }
  },
  async updateTagUser({ dispatch }, payload) {
    const { id, body } = payload
    try {
      const response = (await Vue.axios.put(`/tag-user/update/${id}`, body)).data
      dispatch('getTagUsers', {
        tagId: body.tag
      })
      Vue.swal.fire({
        icon: 'success',
        title: 'Excelente',
        text: 'Usuario actualizado exitosamente..',
        showConfirmButton: true
      })
      return response
    } catch (error) {
      Vue.swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Ocurrió un error al actualizar el usuario.',
        showConfirmButton: true
      })
      return false
    }
  },
  async deleteTagUser({ dispatch }, payload) {
    const { id, tagId } = payload
    try {
      const response = (await Vue.axios.delete(`/tag-user/deleted/${id}`)).data
      Vue.swal.fire({
        icon: 'success',
        title: 'Excelente',
        text: 'Usuario eliminado exitosamente.',
        showConfirmButton: true
      })
      dispatch('getTagUsers', {
        tagId
      })
      return response
    } catch (error) {
      Vue.swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Ocurrió un error al eliminar el usuario.',
        showConfirmButton: true
      })
      return false
    }
  },
  async getTagProjects({ commit }, payload) {
    const { tagId } = payload
    try {
      const response = (await Vue.axios.get(`/tag-project-tag/list/${tagId}`)).data
      const tagProjects = response.map((item) => {
        return {
          project: item.project.id,
          project_image: item.project.image_project,
          name: item.project.name,
          tag: item.tag.id
        }
      })
      commit('setTagProjects', tagProjects)
      return tagProjects
    } catch (error) {
      return error
    }
  },

  async getTagProject({ commit }, payload) {
    const { id } = payload
    try {
      const response = (await Vue.axios.get(`/tag-project/list/${id}`)).data
      commit('setTagProject', response)
      return true
    } catch (error) {
      return error
    }
  },
  async createTagProject({ dispatch }, payload) {
    const { body } = payload
    try {
      const response = (await Vue.axios.post(`/tag-project/create`, body)).data
      dispatch('getTagProjects', {
        tagId: body.tag
      })
      Vue.swal.fire({
        icon: 'success',
        title: 'Excelente',
        text: 'Proyecto agregado exitosamente.',
        showConfirmButton: true
      })
      return response
    } catch (error) {
      Vue.swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Ocurrió un error al agregar el Proyecto.',
        showConfirmButton: true
      })
      return false
    }
  },
  async updateTagProject({ dispatch }, payload) {
    const { id, body } = payload
    try {
      const response = (await Vue.axios.put(`/tag-project/update/${id}`, body)).data
      dispatch('getTagProjects', {
        tagId: body.tag
      })
      Vue.swal.fire({
        icon: 'success',
        title: 'Excelente',
        text: 'Proyecto actualizado exitosamente.',
        showConfirmButton: true
      })
      return response
    } catch (error) {
      Vue.swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Ocurrió un error al actualizar el Proyecto..',
        showConfirmButton: true
      })
      return false
    }
  },
  async deleteTagProject({ dispatch }, payload) {
    const { id, tagId } = payload
    try {
      const response = (await Vue.axios.delete(`/tag-project/deleted/${id}`)).data
      Vue.swal.fire({
        icon: 'success',
        title: 'Excelente',
        text: 'Proyecto eliminado exitosamente.',
        showConfirmButton: true
      })
      dispatch('getTagProjects', {
        tagId
      })
      return response
    } catch (error) {
      Vue.swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Ocurrió un error al eliminar el Proyecto.',
        showConfirmButton: true
      })
      return false
    }
  }
}
export const mutations = {
  setTag(vuexState, payload) {
    vuexState.tag = payload
  },
  setTags(vuexState, payload) {
    vuexState.tags = payload
  },
  setTagsUserAuth(vuexState, payload) {
    vuexState.tagsUserAuth = payload
  },
  setTagUser(vuexState, payload) {
    vuexState.tagUser = payload
  },
  setTagUsers(vuexState, payload) {
    vuexState.tagUsers = payload
  },
  setTagProject(vuexState, payload) {
    vuexState.tagProject = payload
  },
  setTagProjects(vuexState, payload) {
    vuexState.tagProjects = payload
  }
}
