import Vue from 'vue'

export const state = {
  project: {},
  projects: [],
  projectsUserAuth: [],
  powerPlant: {},
  powerPlants: [],
  investor: {},
  investors: [],
  projectUser: {},
  projectUsers: [],
  userDetail: {},
  users: []
}
export const getters = {
  project: ({ project }) => project,
  projects: ({ projects }) => projects,
  projectsUserAuth: ({ projectsUserAuth }) => projectsUserAuth,
  powerPlant: ({ powerPlant }) => powerPlant,
  powerPlants: ({ powerPlants }) => powerPlants,
  investor: ({ investor }) => investor,
  investors: ({ investors }) => investors,
  projectUser: ({ projectUser }) => projectUser,
  projectUsers: ({ projectUsers }) => projectUsers,
  users: ({ users }) => users,
  userDetail: ({ userDetail }) => userDetail
}
export const actions = {
  async getProjects({ commit }) {
    try {
      const response = (await Vue.axios.get(`/project-all/`)).data
      commit('setProjects', response)
      return response
    } catch (error) {
      return error
    }
  },
  async getProjectsUserAuth({ commit }) {
    try {
      const resp = (await Vue.axios.get(`/associate-users-project/`)).data
      commit('setProjectUserAuth', resp)
      return resp
    } catch (error) {
      return error
    }
  },
  async getAllProjects({ commit }) {
    try {
      const resp = (await Vue.axios.get(`/tag-project-all/list?admin=True/`)).data
      commit('setProjectUserAuth', resp)
      return resp
    } catch (error) {
      return error
    }
  },
  async getProject({ commit, dispatch }, payload) {
    const { id } = payload
    try {
      const response = (await Vue.axios.get(`/project/${id}`)).data
      commit('setProject', response)
      await dispatch('getPowerPlants', {
        projectId: id
      })
      await dispatch('getProjectUsers', {
        projectId: id
      })
      return true
    } catch (error) {
      return error
    }
  },
  async createProject({ dispatch }, payload) {
    const { body } = payload
    const { name, image_project } = body
    const formData = new FormData()
    if (name) formData.append('name', name)
    if (image_project) formData.append('image_project', image_project)
    try {
      const response = (await Vue.axios.post(`/project/create`, formData)).data
      dispatch('getProjects')
      Vue.swal.fire({
        icon: 'success',
        title: 'Excelente',
        text: 'Proyecto creado exitosamente.',
        showConfirmButton: true
      })
      return response
    } catch (error) {
      Vue.swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Ocurrió un error al crear el proyecto.',
        showConfirmButton: true
      })
      return false
    }
  },
  async updateProject({ dispatch }, payload) {
    const { id, body } = payload
    const { name, image_project } = body
    const formData = new FormData()
    if (name) formData.append('name', name)
    if (image_project) formData.append('image_project', image_project)
    try {
      const response = (await Vue.axios.put(`/project/update/${id}`, formData)).data
      dispatch('getProjects')
      Vue.swal.fire({
        icon: 'success',
        title: 'Excelente',
        text: 'Proyecto actualizado exitosamente.',
        showConfirmButton: true
      })
      return response
    } catch (error) {
      Vue.swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Ocurrió un error al actualizar el proyecto.',
        showConfirmButton: true
      })
      return false
    }
  },
  async deleteProject({ dispatch }, payload) {
    const { id } = payload
    try {
      await Vue.axios.delete(`/project/deleted/${id}`)
      Vue.swal.fire({
        icon: 'success',
        title: 'Excelente',
        text: 'Proyecto eliminado exitosamente.',
        showConfirmButton: true
      })
      dispatch('getProjects')
      return true
    } catch (error) {
      Vue.swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Ocurrió un error al eliminar el proyecto.',
        showConfirmButton: true
      })
      return false
    }
  },
  async getPowerPlants({ commit }, payload) {
    const { projectId } = payload
    try {
      const response = (await Vue.axios.get(`/power-plant-all/${projectId}`)).data
      commit('setPowerPlants', response)
      return response
    } catch (error) {
      return error
    }
  },
  async getPowerPlant({ commit, dispatch }, payload) {
    const { id } = payload
    try {
      const response = (await Vue.axios.get(`/power-plant/${id}`)).data
      commit('setPowerPlant', response)
      await dispatch('getInvestors', {
        plant: id
      })
      return response
    } catch (error) {
      return error
    }
  },
  async getPowerPlantWeather(_, payload) {
    const url = `https://api.weatherapi.com/v1/forecast.json?key=${process.env.VUE_APP_KEY_WEATHER_API}&q=${payload}&days=3&lang=es&aqi=no&alerts=no`
    const response = (await Vue.axios(url)).data
    return response
  },
  async createPowerPlant({ dispatch }, payload) {
    const { body } = payload
    try {
      const response = (await Vue.axios.post(`/power-plant/create`, body)).data
      Vue.swal.fire({
        icon: 'success',
        title: 'Excelente',
        text: 'Central eléctrica creada exitosamente.',
        showConfirmButton: true
      })
      await dispatch('getPowerPlants', {
        projectId: body.project
      })
      return response
    } catch (error) {
      Vue.swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Ocurrió un error al crear la central eléctrica.',
        showConfirmButton: true
      })
      return false
    }
  },
  async updatePowerPlant({ dispatch }, payload) {
    const { id, body } = payload
    try {
      const response = (await Vue.axios.put(`/power-plant/update/${id}`, body)).data
      Vue.swal.fire({
        icon: 'success',
        title: 'Excelente',
        text: 'Central eléctrica actualizada exitosamente.',
        showConfirmButton: true
      })
      await dispatch('getPowerPlants', {
        projectId: response.project.id
      })
      return true
    } catch (error) {
      Vue.swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Ocurrió un error al actualizar la central eléctrica.',
        showConfirmButton: true
      })
      return false
    }
  },
  async deletePowerPlant({ dispatch }, payload) {
    const { id, projectId } = payload
    try {
      await Vue.axios.delete(`/power-plant/deleted/${id}`)
      Vue.swal.fire({
        icon: 'success',
        title: 'Excelente',
        text: 'Central eléctrica eliminada exitosamente.',
        showConfirmButton: true
      })
      dispatch('getPowerPlants', {
        projectId
      })
      return true
    } catch (error) {
      Vue.swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Ocurrió un error al eliminar la central eléctrica.',
        showConfirmButton: true
      })
      return false
    }
  },
  async getInvestors({ commit }, payload) {
    const { plant } = payload
    try {
      const response = (await Vue.axios.get(`/investor-all/${plant}`)).data
      commit('setInvestors', response)
      return response
    } catch (error) {
      return error
    }
  },
  async getInvestor({ commit, dispatch }, payload) {
    const { id } = payload
    try {
      const response = (await Vue.axios.get(`/investor/${id}`)).data
      commit('setInvestor', response)
      await dispatch(
        'mppt/getMppts',
        {
          investorId: id
        },
        { root: true }
      )
      return true
    } catch (error) {
      return error
    }
  },
  async createInvestor({ dispatch }, payload) {
    const { body } = payload
    try {
      const response = (await Vue.axios.post(`/investor/create`, body)).data
      Vue.swal.fire({
        icon: 'success',
        title: 'Excelente',
        text: 'Inversor creado exitosamente.',
        showConfirmButton: true
      })
      await dispatch('getInvestors', {
        plant: body.plant
      })
      return response
    } catch (error) {
      Vue.swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Ocurrió un error al crear el inversor.',
        showConfirmButton: true
      })
      return false
    }
  },
  async updateInvestor({ dispatch }, payload) {
    const { id, body } = payload
    try {
      const response = (await Vue.axios.put(`/investor/update/${id}`, body)).data
      Vue.swal.fire({
        icon: 'success',
        title: 'Excelente',
        text: 'Inversor actualizado exitosamente.',
        showConfirmButton: true
      })
      await dispatch('getInvestors', {
        plant: body.plant
      })
      return response
    } catch (error) {
      Vue.swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Ocurrió un error al actualizar el inversor.',
        showConfirmButton: true
      })
      return false
    }
  },
  async deleteInvestor({ dispatch }, payload) {
    const { id, plant } = payload
    try {
      await Vue.axios.delete(`/investor/deleted/${id}`)
      Vue.swal.fire({
        icon: 'success',
        title: 'Excelente',
        text: 'Inversor eliminado exitosamente.',
        showConfirmButton: true
      })
      await dispatch('getInvestors', {
        plant
      })
      return true
    } catch (error) {
      Vue.swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Ocurrió un error al eliminar el inversor.',
        showConfirmButton: true
      })
      return false
    }
  },
  async getUsers({ commit }) {
    try {
      let response = (await Vue.axios.get(`/get-users/`)).data
      commit('setUsers', response)
    } catch (error) {
      return error
    }
  },
  async postAssignAdminUser({ commit }, payload) {
    const { boolean, user_id } = payload
    try {
      const response = (
        await Vue.axios.put(`/permits-user/${user_id}`, {
          boolean: String(boolean)
        })
      ).data
      commit('setAssignAdminUser', {
        boolean,
        user_id
      })
      Vue.swal.fire({
        icon: 'success',
        title: 'Excelente',
        text: 'Privilegios de usuario modificados exitosamente.',
        showConfirmButton: true
      })
      return response
    } catch (error) {
      Vue.swal.fire({
        icon: 'error',
        title: 'Error',
        text: error,
        showConfirmButton: true
      })
    }
  },
  async getProjectUsers({ commit }, payload) {
    const { projectId } = payload
    try {
      const response = (await Vue.axios.get(`/associate-project-user/${projectId}`)).data
      commit('setProjectUsers', response.users_project)
    } catch (error) {
      return error
    }
  },
  async getProjectUser({ commit }, payload) {
    const { id } = payload
    try {
      const response = (await Vue.axios.get(`/associate-user-project/${id}`)).data
      commit('setProjectUser', response)
      return true
    } catch (error) {
      return error
    }
  },
  async createProjectUser({ dispatch }, payload) {
    const { body } = payload
    try {
      const response = (await Vue.axios.post(`/associate-user-project/create`, body)).data
      dispatch('getProjectUsers', {
        projectId: body.user_project
      })
      Vue.swal.fire({
        icon: 'success',
        title: 'Excelente',
        text: 'Usuario creado exitosamente.',
        showConfirmButton: true
      })
      return response
    } catch (error) {
      Vue.swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Ocurrió un error al crear el usuario',
        showConfirmButton: true
      })
      return false
    }
  },
  async updateProjectUser({ dispatch }, payload) {
    const { id, body } = payload
    try {
      const response = (await Vue.axios.put(`/associate-user-project/update/${id}`, body)).data
      dispatch('getProjectUsers', {
        projectId: body.user_project
      })
      Vue.swal.fire({
        icon: 'success',
        title: 'Excelente',
        text: 'Usuario actualizado exitosamente.',
        showConfirmButton: true
      })
      return response
    } catch (error) {
      Vue.swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Ocurrió un error al actualizar el usuario',
        showConfirmButton: true
      })
      return error
    }
  },
  async deleteProjectUser({ dispatch }, payload) {
    const { id, projectId } = payload
    try {
      const response = (await Vue.axios.delete(`/associate-user-project/deleted/${id}`)).data
      Vue.swal.fire({
        icon: 'success',
        title: 'Excelente',
        text: 'Usuario eliminado exitosamente.',
        showConfirmButton: true
      })
      dispatch('getProjectUsers', {
        projectId
      })
      return response
    } catch (error) {
      Vue.swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Ocurrió un error al eliminar el usuario.',
        showConfirmButton: true
      })
      return error
    }
  }
}
export const mutations = {
  setAssignAdminUser(state, { boolean, user_id }) {
    state.users.forEach((user) => {
      if (user_id == user.id) {
        user.is_staff = boolean
      }
    })
  },
  setProject(vuexState, payload) {
    vuexState.project = payload
  },
  setProjects(vuexState, payload) {
    vuexState.projects = payload
  },
  setProjectUserAuth(vuexState, payload) {
    vuexState.projectUserAuth = payload
  },
  setPowerPlant(vuexState, payload) {
    vuexState.powerPlant = payload
  },
  setPowerPlants(vuexState, payload) {
    vuexState.powerPlants = payload
  },
  setInvestor(vuexState, payload) {
    vuexState.investor = payload
  },
  setInvestors(vuexState, payload) {
    vuexState.investors = payload
  },
  setProjectUser(vuexState, payload) {
    vuexState.projectUser = payload
  },
  setProjectUsers(vuexState, payload) {
    vuexState.projectUsers = payload
  },
  setUsers(vuexState, payload) {
    vuexState.users = payload
      .sort((a, b) => a.first_name.localeCompare(b.first_name))
      .map((user) => ({
        ...user,
        fullname: user.first_name ? `${user.first_name} ${user.last_name}` : 'Administrador',
        fullname_with_email: user.first_name
          ? `${user.first_name} ${user.last_name} (${user.email})`
          : 'Administrador'
      }))
  },
  setUser(vuexState, payload) {
    vuexState.userDetail = payload
  }
}
