import { isAuthGuardActive } from '@/constants/config'
import { setCurrentUser, getCurrentUser, setToken } from '@/utils'
import Vue from 'vue'

export const state = {
  currentUser: isAuthGuardActive ? getCurrentUser() : {},
  userAuth: null,
  loginError: null,
  processing: false,
  forgotMailSuccess: null,
  resetPasswordSuccess: null
}
export const getters = {
  userAuth: (state) => state.userAuth,
  currentUser: (state) => state.currentUser,
  processing: (state) => state.processing,
  loginError: (state) => state.loginError,
  forgotMailSuccess: (state) => state.forgotMailSuccess,
  resetPasswordSuccess: (state) => state.resetPasswordSuccess,
  userIsAdmin: (state) => () => {
    return state.currentUser?.is_staff
  },
  userIsSuperadmin: (state) => () => {
    return state.currentUser?.is_superuser
  }
}
export const mutations = {
  setUserAuth(state, payload) {
    state.userAuth = payload
  },
  setUser(state, payload) {
    state.currentUser = payload
    state.processing = false
    state.loginError = null
  },
  setLogout(state) {
    state.currentUser = null
    state.processing = false
    state.loginError = null
  },
  setProcessing(state, payload) {
    state.processing = payload
    state.loginError = null
  },
  setError(state, payload) {
    state.loginError = payload
    state.currentUser = null
    state.processing = false
  },
  setForgotMailSuccess(state) {
    state.loginError = null
    state.currentUser = null
    state.processing = false
    state.forgotMailSuccess = true
  },
  setResetPasswordSuccess(state) {
    state.loginError = null
    state.currentUser = null
    state.processing = false
    state.resetPasswordSuccess = true
  },
  clearError(state) {
    state.loginError = null
  }
}
export const actions = {
  async register({ commit }, payload) {
    commit('clearError')
    commit('setProcessing', true)
    try {
      const data = (await Vue.axios.post('/auth/signup', payload)).data
      Vue.swal.fire({
        icon: 'success',
        title: 'Excelente',
        text: 'Registro Exitoso',
        showConfirmButton: true
      })
      return data
    } catch (error) {
      commit('setError', error.message)
      let message = 'Ocurrió un error al registrar el usuario.'
      if (error.response?.data?.non_field_errors?.length) {
        message = 'Este correo electrónico ya se encuentra registrado.'
      }
      setTimeout(() => {
        commit('clearError')
      }, 3000)
      Vue.swal.fire({
        icon: 'error',
        title: 'Error',
        text: message,
        showConfirmButton: true
      })
      return false
    } finally {
      commit('setProcessing', false)
    }
  },
  async login({ commit, dispatch }, payload) {
    commit('clearError')
    commit('setProcessing', true)
    try {
      const response = (await Vue.axios.post('/auth/token', payload)).data
      if (response.access) {
        setToken(response)
        commit('setUserAuth', {
          ...response
        })
        await dispatch('getProfile')
        return true
      }
    } catch (error) {
      setCurrentUser(null)
      commit('setError', error.message)
      setTimeout(() => {
        commit('clearError')
      }, 3000)
      const { data } = error.response
      if (data.email) {
        data.email.forEach((errorCurrent) => {
          Vue.swal.fire({
            icon: 'error',
            title: 'Error',
            text: errorCurrent,
            showConfirmButton: true
          })
        })
      } else {
        Vue.swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Contraseña incorrecta',
          showConfirmButton: true
        })
      }
      return false
    }
  },
  async loginGoogle({ commit, dispatch }, payload) {
    try {
      const response = (await Vue.axios.get('/auth/login/google', { params: payload })).data
      if (response) {
        await setToken(response)
        await commit('setUserAuth', {
          ...response
        })
        await dispatch('getProfile')
        return true
      }
    } catch (error) {
      setCurrentUser(null)
      Vue.swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Error al iniciar sesión',
        showConfirmButton: true
      })
      return error
    }
  },
  async getProfile({ commit, dispatch }) {
    try {
      const response = (await Vue.axios.get(`/my-profile`)).data
      commit('setUserAuth', {
        ...response
      })
      setCurrentUser({
        ...response
      })
      commit('setUser', {
        ...response
      })
      return response
    } catch (error) {
      dispatch('notification/info', 'Para entrar por favor inicie sesión nuevamente.', {
        root: true
      })
    }
  },

  async updateProfile({ commit }, payload) {
    const { first_name, last_name, email, phone, address, image } = payload
    const formData = new FormData()
    if (first_name) formData.append('first_name', first_name)
    if (last_name) formData.append('last_name', last_name)
    if (email) formData.append('email', email)
    if (phone) formData.append('phone', phone)
    if (address) formData.append('address', address)
    if (image) formData.append('image', image)

    try {
      const response = (await Vue.axios.put('my-profile/update', formData)).data
      setCurrentUser({
        ...response
      })
      commit('setUser', {
        ...response
      })
      Vue.swal.fire({
        icon: 'success',
        title: 'Excelente',
        text: 'Perfil actualizado correctamente.',
        showConfirmButton: true
      })
      return response
    } catch (error) {
      Vue.swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Ocurrió un error al actualizar el perfil',
        showConfirmButton: true
      })
      return false
    }
  },
  async forgotPassword({ commit }, { email }) {
    commit('clearError')
    commit('setProcessing', true)
    try {
      const resp = await Vue.axios.post('/password-reset-email/', { email })
      Vue.swal.fire({
        icon: 'success',
        title: 'Excelente',
        text: 'Enlace enviado correctamente al correo ingresado.',
        showConfirmButton: true
      })
      return resp
    } catch (error) {
      Vue.swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Ocurrió un error al enviar el Correo Electrónico para recuperar contraseña',
        showConfirmButton: true
      })
      return false
    } finally {
      commit('setProcessing', false)
    }
  },
  async validateTokenResetPassword({ commit }, payload) {
    commit('clearError')
    commit('setProcessing', true)
    try {
      const resp = await Vue.axios.get(`/password-reset/${payload.code}/${payload.token}`)
      return resp
    } catch (error) {
      Vue.swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'El token de validación es incorrecto, intentalo nuevamente',
        showConfirmButton: true
      })
      commit('clearError')
      return error.response
    } finally {
      commit('setProcessing', false)
    }
  },
  async sendVerifyEmail({ commit }, payload) {
    commit('clearError')
    commit('setProcessing', true)
    try {
      const resp = (await Vue.axios.get(`/auth/account-activate?token=${payload}`)).data
      return resp
    } catch (error) {
      Vue.swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'El token de validación es incorrecto, intentalo nuevamente',
        showConfirmButton: true
      })
      commit('clearError')
      return error.response
    } finally {
      commit('setProcessing', false)
    }
  },
  async resendEmailVerification({ commit }, payload) {
    commit('clearError')
    commit('setProcessing', true)
    const { user_id } = payload
    try {
      const resp = await Vue.axios.get(`/auth/account-check/${user_id}`)
      Vue.swal.fire({
        icon: 'success',
        title: 'Excelente',
        text: 'Enlace de verificación reenviado correctamente al correo electrónico.',
        showConfirmButton: true
      })
      return resp
    } catch (error) {
      Vue.swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Ocurrió un error al reenviar el enlace',
        showConfirmButton: true
      })
      commit('clearError')
      return error.response
    } finally {
      commit('setProcessing', false)
    }
  },
  async resetPassword({ commit }, payload) {
    commit('clearError')
    commit('setProcessing', true)
    try {
      const resp = await Vue.axios.patch('/password-reset-complete/', payload)
      Vue.swal.fire({
        icon: 'success',
        title: 'Excelente',
        text: '¡Contraseña recuperada correctamente!',
        showConfirmButton: true
      })
      return resp
    } catch (error) {
      Vue.swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Ocurrió un error al cambiar la contraseña',
        showConfirmButton: true
      })
      commit('clearError')
      return error.response
    } finally {
      commit('setProcessing', false)
    }
  },
  async signOut({ commit, dispatch }) {
    try {
      await Vue.axios.post('/auth/logout', {})
      setCurrentUser(null)
      setToken(null)
      commit('setUser', null)
      commit('setUserAuth', null)
    } catch (error) {
      dispatch('notification/error', 'Ocurrió un error al finalizar la sesión', { root: true })
    }
  }
}
