import Vue from 'vue'

export const state = {
  mppt: {},
  mppts: []
}
export const getters = {
  mppt: ({ mppt }) => mppt,
  mppts: ({ mppts }) => mppts
}
export const actions = {
  async getMppts({ commit }, payload) {
    const { investorId } = payload
    try {
      const response = (await Vue.axios.get(`/mppt/list/investor/${investorId}`)).data
      commit('setMppts', response)
    } catch (error) {
      return error
    }
  },
  async getMppt({ commit, dispatch }, payload) {
    const { id } = payload
    try {
      const response = (await Vue.axios.get(`/mppt/list/${id}`)).data
      commit('setMppt', response)
      await dispatch(
        'string/getStrings',
        {
          mpptId: id
        },
        { root: true }
      )
      return true
    } catch (error) {
      return error
    }
  },
  async createMppt({ dispatch }, payload) {
    const { body } = payload
    try {
      const response = (await Vue.axios.post(`/mppt/create`, body)).data
      Vue.swal.fire({
        icon: 'success',
        title: 'Excelente',
        text: 'MPPT creado exitosamente.',
        showConfirmButton: true
      })
      await dispatch('getMppts', {
        investorId: body.investor
      })
      return response
    } catch (error) {
      Vue.swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Ocurrió un error al crear el MPPT.',
        showConfirmButton: true
      })
      return false
    }
  },
  async updateMppt({ dispatch }, payload) {
    const { id, body } = payload
    try {
      const response = (await Vue.axios.put(`/mppt/update/${id}`, body)).data
      Vue.swal.fire({
        icon: 'success',
        title: 'Excelente',
        text: 'MPPT actualizado exitosamente.',
        showConfirmButton: true
      })
      await dispatch('getMppts', {
        investorId: body.investor
      })
      return response
    } catch (error) {
      Vue.swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Ocurrió un error al actualizar el MPPT.',
        showConfirmButton: true
      })
      return false
    }
  },
  async deleteMppt({ dispatch }, payload) {
    const { id, investorId } = payload
    try {
      await Vue.axios.delete(`/mppt/delete/${id}`)
      Vue.swal.fire({
        icon: 'success',
        title: 'Excelente',
        text: 'MPPT eliminado exitosamente.',
        showConfirmButton: true
      })
      dispatch('getMppts', {
        investorId
      })
      return true
    } catch (error) {
      Vue.swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Ocurrió un error al eliminar el MPPT.',
        showConfirmButton: true
      })
      return false
    }
  }
}
export const mutations = {
  setMppt(vuexState, payload) {
    vuexState.mppt = payload
  },
  setMppts(vuexState, payload) {
    vuexState.mppts = payload
  }
}
